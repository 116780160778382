import AdTournaments from "./Dashboard-components/Admin/AdTournaments";
import { getUser } from "../../helpers/auth";
import { useEffect, useState } from "react";

const UserMainIndex = () => {
  const [user, setUser] = useState("");

  const checkAuth = async () => {
    let b_user = await getUser();
    // console.log(b_user);
    setUser(b_user);
  };

  useEffect(() => {
    checkAuth();
  }, []);
  return (
    <>
      {user && user.role === "admin" ? (
        <AdTournaments />
      ) : (
        <h1>no admin found</h1>
      )}
    </>
  );
};

export default UserMainIndex;
