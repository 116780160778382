import axios from "axios";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { server_url } from "../../../../helpers/auth";

const RankTable = ({ d }) => {
  const [data, setData] = useState(d);
  const [oreder, setOrder] = useState("ASC");
  const sorting = (col) => {
    if (col === "name" || col === "username" || col === "platform") {
      if (oreder === "ASC") {
        const sorted = [...data].sort((a, b) =>
          a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
        );
        setData(sorted);
        setOrder("DSC");
      }
      if (oreder === "DSC") {
        const sorted = [...data].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        );
        setData(sorted);
        setOrder("ASC");
      }
    } else {
      if (oreder === "ASC") {
        const sorted = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
        setData(sorted);
        setOrder("DSC");
      }
      if (oreder === "DSC") {
        const sorted = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
        setData(sorted);
        setOrder("ASC");
      }
    }
  };

  const Tr = (d, i) => {
    const delete_handler = async () => {
      try {
        let con = window.confirm(`Are you sure want to delete ${d.username}?`);
        if (!con) {
          return;
        }
        const url = `${server_url}/api/delete_rank_player`;
        let _token = localStorage.getItem("token");
        let res = await axios.post(url, { _id: d._id, _token });
        if (res.data.success) {
          toast.warning(`Player ${d.username} deleted.`);
          window.location.reload();
        }
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    return (
      <>
        <tr key={`__d_${i}`}>
          <th scope="row">{i + 1}</th>
          <td>{d.name}</td>
          <td>{d.username}</td>
          <td>{d.platform.toUpperCase()}</td>
          <td>{d.wins}</td>
          <td>{d.kills}</td>
          <td>{d.kdRatio.toFixed(2)}</td>
          <td>
            <button onClick={delete_handler} className="button_2 delete">
              Delete
            </button>
          </td>
        </tr>
      </>
    );
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <table className="table table-striped wh">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th className="pointer" onClick={() => sorting("name")} scope="col">
              Name
            </th>
            <th
              className="pointer"
              onClick={() => sorting("username")}
              scope="col"
            >
              USERNAME
            </th>
            <th
              className="pointer"
              onClick={() => sorting("platform")}
              scope="col"
            >
              PLATFORM
            </th>
            <th className="pointer" onClick={() => sorting("wins")} scope="col">
              WINS
            </th>
            <th
              className="pointer"
              onClick={() => sorting("kills")}
              scope="col"
            >
              KILLS
            </th>
            <th
              className="pointer"
              onClick={() => sorting("kdRatio")}
              scope="col"
            >
              KD
            </th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>{data.map(Tr)}</tbody>
      </table>
    </>
  );
};
export default RankTable;
