import { useEffect, useState } from "react";
import axios from "axios";
import { server_url } from "../../../../helpers/auth";
import { toast, ToastContainer } from "react-toastify";
import reduce_image from "../../../../helpers/image_handler"
const AdPreTournaments = () => {
  const [tourData, setTourData] = useState("");
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    try {
      setLoading(true);
      const link = `${server_url}/api/get_tournaments`;
      let res = await axios.get(link);
      if (res.data.success) {
        setTourData(res.data.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const Stour = (t, i) => {
    const img_to_str = async(avater) => {
     
        let result = await reduce_image(avater.target.files[0])
        let src = document.getElementById(`view_updated_img_${i}`);
        src.setAttribute("src", result);
  
          };
    const img_to_str_2 = async (avater) => {
    
        let result = await reduce_image(avater.target.files[0]);
        let src = document.getElementById(`view_updated_img_banner_${i}`);
        src.setAttribute("src", result);
     
    };
    const updater = async (e) => {
      try {
        e.preventDefault();
        console.log("called");
        toast.warning("Updating..");
        const avater = e.target.avater.src;
        const name = e.target._name.value;
        const status = e.target.status.value;
        const p = e.target.p.value;
        const banner = e.target.banner.src;

        const _id = t._id;
        const link = `${server_url}/api/update_tournaments`;
        let _token = localStorage.getItem("token");

        let submit_obj = {
          avater,
          name,
          status,
          _id,
          p,
          banner,
          _token,
        };

        let res = await axios.post(link, submit_obj);

        if (res.data.success) {
          toast.success("Tournament Updated.");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong.");
      }
    };

    const delete_tour = async () => {
      try {
        let con = window.confirm(
          "Are you sure want to delete this tournament?"
        );
        if (!con) {
          return;
        }
        console.log("deleting");
        const link = `${server_url}/api/delete_tournaments`;
        let token = localStorage.getItem("token");
        let submit_obj = {
          _token: token,
          _id: t._id,
        };
        let res = await axios.post(link, submit_obj);
        if (res.data.success) {
          toast.warning("Tournament Deleted.");
          getData();
        }
      } catch (error) {
        toast.error("Something went wrong.");
      }
    };
    return (
      <>
        <div key={`_${i}`} className="tour_block" data-aos={t.aos}>
          <form onSubmit={updater}>
            <div className="img">
              <div className="update_img">
                <input
                  type="file"
                  onChange={img_to_str}
                  accept="image/png, image/jpg"
                />
                <input
                  type="file"
                  onChange={img_to_str_2}
                  accept="image/png, image/jpg"
                />
                <img
                  src={t.banner}
                  name="banner"
                  id={`view_updated_img_banner_${i}`}
                  alt=""
                />
              </div>
              <img
                name="avater"
                id={`view_updated_img_${i}`}
                src={t.avater}
                alt="avatar"
              />
            </div>
            <div className="tour_content">
              <input id="name" type="text" name="_name" defaultValue={t.name} />
              <input
                id="status"
                type="text"
                name="status"
                defaultValue={t.status}
              />
              <textarea id="p" type="text" name="p" defaultValue={t.p} />
            </div>

            <button type="submit" className="button_2">
              Update
            </button>
          </form>
          <button onClick={delete_tour} className="button_2 delete">
            Delete
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="upcomming_tournaments">
        <div className="container">
          <h1>update tournaments</h1>
          {loading ? <h1>Loading...</h1> : null}
          <div className="tour_blocks">{tourData && tourData.map(Stour)}</div>
        </div>
      </section>
    </>
  );
};
export default AdPreTournaments;
