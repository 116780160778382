import { useEffect, useState } from "react";
import axios from "axios";
import RankTable from "./RankTable";
import { server_url } from "../../../../helpers/auth";
import AdToLeader from "./AdToLeader";
const AdLeaderBoard = () => {
  let [serData, setSerData] = useState();
  let [loading, setLoading] = useState(false);

  let getPlayer = async (game) => {
    try {
      setSerData("");
      setLoading(true);
      const url = `${server_url}/api/game_data`;
      let res = await axios.post(url, { game_name: game });

      if (res.data.success) {
        setLoading(false);
        filter(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPlayer("warzone");
  }, []);

  const game_handler = async () => {
    let select = document.getElementById("l_game_name").value;
    await getPlayer(select);
  };

  const filter = (unf_data) => {
    let filtered = [];
    unf_data.map((d) => {
      let obj = {
        _id: d.user_data._id,
        name: d.user_data.name,
        username: d.user_data.username,
        platform: d.user_data.platform,
        kills: parseInt(d.lifetime_all.kills),
        wins: parseInt(d.lifetime_all.wins),
        kdRatio: parseFloat(d.lifetime_all.kdRatio),
      };
      filtered.push(obj);
      if (unf_data.length === filtered.length) {
        return setSerData(filtered);
      }
    });
  };

  return (
    <>
      <section className="player_rank bg_pic_3">
        <div className="container">
          <div className="player_rank_table">
            <h1>Player Leader Board</h1>
            <select
              defaultValue=""
              onChange={game_handler}
              name="game_name"
              id="l_game_name"
            >
              <option selected value="warzone">
                Warzone
              </option>
              <option value="vanguard">Vanguard</option>
              <option value="coldwar">Coldwar</option>
              <option value="modern_warfare">Modern_warfare</option>
            </select>

            {!loading && serData ? <RankTable d={serData} /> : null}
            <p>
              {loading && !serData
                ? "Loading Data..."
                : serData
                ? null
                : "No data found!"}
            </p>
          </div>
        </div>
      </section>
      <AdToLeader />
    </>
  );
};

export default AdLeaderBoard;
