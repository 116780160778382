import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import AdPreTournaments from "./AdPreTournaments";
import { server_url } from "../../../../helpers/auth";
const AdTournaments = () => {
  document.title = "COD BD || Tournaments ";

  const submit_handler = async (e) => {
    try {
      e.preventDefault();
      toast.warning("Sending Data..");

      const name = e.target.name.value;
      const avater = e.target.avater;
      const status = e.target.status.value;
      const banner = e.target.banner;
      const p = e.target.p.value;
      const aos = "fade-right";

      if (!name || !avater.value || !status || !aos || !banner || !p) {
        return toast.error("Fill all the inputs.");
      }

      const readers = new FileReader();
      readers.addEventListener("load", () => {
        let img_url = readers.result;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
          let img_urls = reader.result;

          send(name, aos, img_url, status, img_urls, p);
        });
        reader.readAsDataURL(banner.files[0]);
      });
      readers.readAsDataURL(avater.files[0]);

      async function send(name, aos, img, status, banner, p) {
        try {
          const link = `${server_url}/api/add_tournaments`;
          let _token = localStorage.getItem("token");

          const send_data = {
            name,
            aos,
            img,
            status,
            banner,
            p,
            _token,
          };
          console.log(send_data);
          let res = await axios.post(link, send_data);

          if (res.data.success) {
            toast.success("Tournament has created.");
            e.target.reset();
          }
        } catch (error) {
          toast.error("Something Went wrong.");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Create = () => {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AdPreTournaments />
        <div className="container">
          <form className="tour_create_form" onSubmit={submit_handler}>
            <h2>Create Tournaments</h2>
            <div className="inputs">
              <input
                type="text"
                name="name"
                id=""
                placeholder="Tournament Name"
              />

              <input
                type="text"
                name="status"
                id=""
                placeholder="Tournament Status"
              />
              <div className="files">
                <input
                  accept="image/png, image/jpg"
                  type="file"
                  name="avater"
                  id="fileupload_av"
                  placeholder="Tournament Image"
                />
                <label htmlFor="fileupload_av">Image</label>
              </div>
              <div className="files">
                <input
                  accept="image/png, image/jpg"
                  type="file"
                  name="banner"
                  id="fileupload"
                  placeholder="Tournament banner"
                />
                <label htmlFor="fileupload">Banner</label>
              </div>

              <textarea
                name="p"
                id=""
                cols="30"
                rows="10"
                placeholder="Tournament texts as HTML format"
              ></textarea>
            </div>
            <input type="submit" value={"CREATE"} />
          </form>
        </div>
      </>
    );
  };

  return (
    <>
      <h1>Admin Tournaments</h1>

      <Create />
    </>
  );
};
export default AdTournaments;
