import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { server_url } from "../../../../helpers/auth";
const AdToLeader = () => {
  const form_handler = async (e) => {
    e.preventDefault();
    const p_name = e.target.name.value;
    const p_game = e.target.game.value;
    const p_tag = e.target.tag.value;
    const p_platform = e.target.platform.value;

    if (!p_name) {
      return toast.error("Please fill Name input.");
    } else if (!p_game) {
      return toast.error("Please fill Game input.");
    } else if (!p_tag) {
      return toast.error("Please fill Tag input.");
    } else if (!p_platform) {
      return toast.error("Please fill Platform input.");
    }
    try {
      const update_url = `${server_url}/api/add_leader_player`;
      let submit_obj = {
        p_name,
        p_game,
        p_tag,
        p_platform,
      };
      let response = await axios.post(update_url, submit_obj);
      if (response.data.success) {
        e.target.reset();
        toast.success(response.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.dir(error);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wron");
      }
    }
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section id="add_leader">
        <div className="container">
          <h1>Add to Leaderboard</h1>
          <form action="" className="add_leader_form" onSubmit={form_handler}>
            <div>
              <p>Player Name</p>
              <input type="text" name="name" placeholder="Your Name" />
            </div>
            <div>
              <p>Game Name</p>
              <select name="game" id="">
                <option value="" selected disabled>
                  Select One
                </option>

                <option value="warzone">Warzone</option>
                <option value="vanguard">Vanguard</option>
                <option value="coldwar">Coldwar</option>
                <option value="modern_warfare">Modern_warfare</option>
              </select>
            </div>
            <div>
              <p>Player username</p>
              <input type="text" name="tag" placeholder="Your username" />
            </div>
            <div>
              <p>Game Platform</p>
              <select name="platform" id="">
                <option value="" selected disabled>
                  Select One
                </option>
                <option value="psn">PSN</option>
                <option value="battle">Battlenet</option>
                <option value="xbl">XBOX</option>
                <option value="steam">Steam</option>
                <option value="acti">Activision</option>
                <option value="uno">Uno</option>
              </select>
            </div>
            <button type="submit" className="button_2">
              Submit
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default AdToLeader;
