import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard/Dashboard";
import ChangePassword from "./pages/dashboard/Dashboard-components/ChangePassword";
import UserMainIndex from "./pages/dashboard/UserMainIndex";
import AdLeaderBoard from "./pages/dashboard/Dashboard-components/Admin/AdLeaderBoard";
import LogOut from "./pages/login/LogOut";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/logout" element={<LogOut />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<UserMainIndex />} />
            <Route path="change-pass" element={<ChangePassword />} />
            <Route path="leader_board" element={<AdLeaderBoard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
